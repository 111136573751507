import React, { Component, useState, useContext } from 'react';

import Layout from "../components/layout"
import fade from "../components/fade"
import { useTriggerTransition } from 'gatsby-plugin-transition-link'
import { Link } from "gatsby"
import { navigate } from "gatsby"
// import ScrollMenu from "react-horizontal-scrolling-menu"
import Shuffle from 'shufflejs';
import LinkFade from '../components/LinkFade';
import Slide from "../components/slide"

import SwiperCore, { Controller, Parallax, Scrollbar, Pagination, Navigation, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Controller, Parallax, Scrollbar, Pagination, Navigation, EffectFade]);

const Menu = ({ location, pageContext, entry }) => {
  const data = pageContext.menuData;
  const [sidebarSlider, setSidebarSlider] = useState(null);
  var lastSlideNo = -1;
  const [activeTag, setActiveTag] = useState((entry.state && entry.state.opts && entry.state.opts !== "back") ? entry.state.opts[0] : "all");
  const activeIcon = entry.state && entry.state.opts && entry.state.opts !== "back" && entry.state.opts[1];
  // const triggerTransition = useTriggerTransition({
  //   exit: {
  //     length: 0.8,
  //     trigger: ({ exit, node }) => fade({ exit, node, direction: 'out' }),
  //   },
  //   entry: {
  //     length: 0.8,
  //     delay: 0.5,
  //     trigger: ({ exit, node }) => fade({ exit, node, direction: 'in' }),
  //     // state: {"o": document.querySelector(".menu-page-items .tags .active").innerText}
  //   },
  // })

  const changeSlideshow = (slideNo, x) => {
    console.log([lastSlideNo, slideNo]);
    if (lastSlideNo != slideNo) {
      lastSlideNo = slideNo;
      sidebarSlider.slideTo(slideNo+1, 150);
    }
  }

  if (data === undefined) {
    return "";
  }

  if (data === null)
    return <></>;

  // const tags = Array();
  // data.Icons.forEach((icon, i) => {
  //   icon.tags && icon.tags.forEach((tag, i) => {
  //     if (tags.indexOf(tag.Tag) === -1) {
  //       tags.push(tag.Tag);
  //     };
  //   });
  // });

  var bigtags = []
  data.Icons.forEach((icon, i) => {bigtags = bigtags.concat(icon.tags)})
  bigtags.sort((a,b) => (a.Order-b.Order))

  const tags = Array();
  bigtags.forEach((tag, i) => {
    if (tags.indexOf(tag.Tag) === -1) {
      tags.push(tag.Tag);
    };
  });

  const getInfoPanelTitle = (item) => {
    var title = "";
    if (item && item.InfoPanelTitle) {
      // if (language == "maori" && item.InfoPanelTitleMaori) {
      //   title = item.InfoPanelTitleMaori;
      // } else {
        title = item.InfoPanelTitle;
      // }
    }
    return title.replace(/DL[0-9]{1,2}[- ]?/, "");
  }

  const getText = (item) => {
    var info = "";
    if (item && item.Text) {
      // if (language == "maori" && item.TextMaori) {
      //   info = item.TextMaori;
      // } else {
        info = item.Text;
      // }
    }
    return info;
  }

  const introSlide = {
    Title: getInfoPanelTitle(data),
    Body: getText(data)
  }

  var dataRows = data.Icons.length < 4 ? 1 : (data.ShowInfoPanel ? 3 : 2)

  var _self = this;
  const scrollToIcon = (iconId) => {
    // console.log("scrollToIcon" + iconId)
    if (iconId === null) { return }
    // console.log("scrollToIconX" + iconId)
    var icon = document.querySelector(".grid-icon-" + iconId);
    if (icon !== null) {
      var leftPad = icon.offsetLeft - 1920/2;
      document.querySelector(".menu-page-items-container").scroll(leftPad, 0);
    }

    // document.querySelector(".menu-page-items-container").scroll({
    //   top: 0,
    //   left: 200,
    //   behavior: 'smooth'
    // })
  }

  const setTag = (tag) => {
    // console.log("setTag-"+tag);
    // console.log([activeTag, tag]);
    if (activeTag !== tag) {
      setActiveTag(tag);
    }
    document.querySelectorAll(".tags a").forEach((element) => {
      if (element.innerText.toLowerCase() === tag.toLowerCase()) {
        if (element.classList.toString().indexOf("active") < 0) {
          element.classList.add("active");
        }
      } else {
        element.classList.remove("active");
      }
    });

    var itemCount = 0;
    document.querySelectorAll(".grid-icon").forEach((item) => {
      if (tag === "all" || item.getAttribute("data-groups").toLowerCase().indexOf(tag.toLowerCase()) > -1) {
        itemCount++;
        item.classList.remove("hidden-icon");
      } else {
        item.classList.add("hidden-icon");
      }
    });
    if (itemCount == 0) {
      itemCount = document.querySelectorAll(".grid-icon").length;
    }

    var pageContainerWidth = parseInt(window.getComputedStyle(document.querySelector(".menu-page-items-container")).width);
    // console.log("pageContainerWidth:" + pageContainerWidth);

    // var newIconContainerWidth = newIconContainerWidth < 1244 ? 1244 : newIconContainerWidth;
    var iconWidth = parseInt(window.getComputedStyle(document.querySelector(".grid-icon-size")).width)+10;
    // console.log("iconWidth:" + iconWidth);
    var dataRows = data.Icons.length < 4 ? 1 : (data.ShowInfoPanel ? 3 : 2)
    // console.log("dataRows:" + dataRows);
    var newIconContainerWidth = iconWidth * Math.ceil(itemCount / dataRows);
    // console.log("newIconContainerWidth:" + newIconContainerWidth);
    newIconContainerWidth = newIconContainerWidth < pageContainerWidth ? pageContainerWidth : newIconContainerWidth;
    // console.log("newIconContainerWidth:" + newIconContainerWidth);

    document.querySelector(".menu-page-items").style.width = newIconContainerWidth + "px";
  }


  var tag_container = "";
  if (tags !== null && tags.length > 0) {
    tag_container = <div className="tags-container">
      <div className="tags">
        <a className={activeTag === "all" ? "active" : ""} role="button" onClick={() => {setTag("all")}}>All</a>
        {tags.map((tag) => {
          // console.log([this.activeTag, tag])
          return <a key={"tag_link" + tag} className={activeTag === tag ? "active" : ""} role="button" onClick={() => {setTag(tag)}}>{tag}</a>;
        })}
      </div>
    </div>
  }

  const getTitle = (item) => {
    var title = "";
    if (item && item.Title) {
      // if (language == "maori" && item.TitleMaori) {
      //   title = item.TitleMaori;
      // } else {
        title = item.Title;
      // }
    }
    return title.replace(/DL[0-9]{1,2}[- ]?/, "");
  }

  const getInfo = (item) => {
    var info = "";
    if (item && item.Info) {
      // if (language == "maori" && item.InfoMaori) {
      //   info = item.InfoMaori;
      // } else {
        info = item.Info;
      // }
    }
    return info;
  }

  return (
    <Layout className={"menu-page " + "menu-page-"+data.strapiId} homePath={pageContext.homePath} styles={pageContext.styles} timeoutOverride={data.TimeoutOverrideSeconds}>
      {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
      {/* <pre>pageContext{JSON.stringify(entry, null, 4)}</pre> */}
      {/* <pre>{JSON.stringify(pageContext.styles, null, 4)}</pre> */}
      <div className="page-container">
        <nav className="nav">
          {/* <Link className="home" to={pageContext.homePath}></Link> */}
          {/* <a className="back" onClick={() => triggerTransition({  to: `${pageContext.backPath}?back` })}></a> */}
          <LinkFade key={Math.random()} opts="back" url={`${pageContext.backPath}`}><div className="back"></div></LinkFade>
        </nav>

        <div className={"menu-page-icon-container " + (data.ShowInfoPanel ? " with-sidebar" : " without-sidebar")}>
          {/* <h3 className="subpage-touchscreen-title">{ pageContext.title.replace(/DL[0-9]{1,2}[- ]?/, "") }</h3> */}
          <h1>{ getTitle(data) }</h1>

          <div className="menu-page-items-container">
            <div className="menu-page-items" data-rows={dataRows} onLoad={() => {setTag(activeTag);scrollToIcon(activeIcon)}}>
              {tag_container}

              {/* <PhotoGrid key={data.Title} tags={tags} icons={data.Icons} homePath={pageContext.homePath} clickCallback={changeSlideshow.bind(this)}/> */}
              { data.Icons.map((icon, i) => {
                var itemTagsList = [];
                var itemTags = "";

                if (icon.tags !== null && icon.tags.length > 0) {
                  itemTagsList = icon.tags.map((t) => {return t.Tag});
                  itemTags = itemTagsList.join('", "');
                }

                if (icon.slideshow && icon.slideshow.id) {
                  var linkType = "slideshow";
                  var targetId = icon.slideshow.id;
                } else if (icon.menu_page && icon.menu_page.id) {
                  var linkType = "menu";
                  var targetId = icon.menu_page.id;
                } else {
                  var linkType = "static";
                  var targetId = null;
                }

                var image = null;
                if (icon.Image && icon.Image.imagePath) {
                  image = <img loading="eager" src={icon.Image.imagePath} />
                }
                // console.log("x: " + activeTag)
                // console.log("x: " + linkType)
                if (linkType !== "static") {
                  var link = <div className={"grid-icon grid-icon-" + icon.id + (activeTag === "all" || (itemTagsList.indexOf(activeTag) > -1) ? "" : " hidden-icon")} data-groups={'["'+itemTags+'"]'} onClick={(e) => {e.target.classList.add("highlight")}}>
                    <LinkFade key={"menu_item" + icon.id} url={`${pageContext.homePath}/${linkType}/${targetId}`} opts={[activeTag,icon.id]}> {/*onClick={() => {triggerTransition({to:`${pageContext.homePath}/${linkType}/${targetId}`})}}> */}
                      { image }
                      <p>{ getTitle(icon) }</p>
                    </LinkFade>
                  </div>;
                } else {
                  var link = <div className={"grid-icon " + (activeTag === "all" || (itemTagsList.indexOf(activeTag) > -1) ? "" : "hidden-icon")} data-groups={'["'+itemTags+'"]'} onClick={(e) => {changeSlideshow(i);document.querySelectorAll(".highlight").forEach((elm) => {elm.classList.remove("highlight");});e.target.classList.add("highlight");}}>
                    <a key={"menu_item" + icon.id}> {/*onClick={() => {triggerTransition({to:`${pageContext.homePath}/${linkType}/${targetId}`})}}> */}
                      { image }
                      <p>{ getTitle(icon) }</p>
                    </a>
                  </div>;
                }

                return link;
              })}
            </div>
          </div>
        </div>
        {/* <pre>{JSON.stringify(data.Icons, null, 4)}</pre> */}
        {data.ShowInfoPanel && <div className="menu-page-sidebar">
          <Swiper className="swiper-infobox"
            speed={600}
            preventInteractionOnTransition={true}
            allowTouchMove={false}
            loop={false}
            fadeEffect={{ crossFade: true }}
            allowTouchMove={false}
            effect={"fade"}
            onSwiper={setSidebarSlider}
            direction={"vertical"}
            >
            <SwiperSlide key={Math.random()}><Slide key={99999} data={introSlide} counter={-1} mode="sidebar"></Slide></SwiperSlide>
            { data.Icons.map((icon, i) => {
                var slide = {
                  Title: getTitle(icon),
                  Body: getInfo(icon),
                }
              return <SwiperSlide key={Math.random()}><Slide key={Math.random()} data={slide} counter={-1} mode="sidebar"></Slide></SwiperSlide>
            })}
          </Swiper>
        </div>}
      </div>
      <div className={"grid-icon-size " + (data.ShowInfoPanel ? " with-sidebar" : " without-sidebar")}></div>
    </Layout>
  )
}

export default Menu
